






















































































































































































































































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator"
import House from "~/models/House"
import { autocompletePlace, geocodePlaceId } from "~/plugins/places"
import {
  houseFormData,
  HouseFormDataField,
} from "@/components/form/HouseFormData"
import { getEnumValues } from "@/store"
import { preventNonNumericKeys, isValidPhoneNumber } from "~/utils/validate"
import OcularRating from "~/components/OcularRating/OcularRating.vue"

@Component({
  components: {
    OcularRating,
  },
})
export default class HouseForm extends Vue {
  @Prop({ required: true }) house!: House
  valid = true
  activeTab = 0
  placeId = ""
  searchAddress = ""
  predictedPlaces: google.maps.places.AutocompletePrediction[] = []
  rulesNumber = [
    // eslint-disable-next-line use-isnan
    (val: any) => val == null || val != "",
  ]
  integerRequired = (val: any) => val !== null && val != ""

  get tabsPerPropertyType() {
    return houseFormData.filter((tab) => {
      const tabType = tab.type
      return this.house.id
        ? tabType == "all" || tabType == this.propertyType
        : tabType == "new"
    })
  }
  get propertyType() {
    return ["all", "house", "flat"][this.house.property_type]
  }
  get placeHolderAdditionalInfo() {
    if (this.house.verification) return ""
    else return "Pour prendre des notes, vous devez démarrer une vérification"
  }
  @Watch("house.property_type")
  setActiveTab() {
    if (this.house.id) this.activeTab = 0
  }
  @Watch("searchAddress")
  async findAdresses(value: string) {
    if (!value) return
    const results = await autocompletePlace(value)
    if (results) this.predictedPlaces = results
  }
  async fetchHouseAddressDetails() {
    const details = await geocodePlaceId(this.placeId)
    const localityComponent = details.address_components.find((component) =>
      component.types.find((t) => t === "locality")
    )
    if (localityComponent) this.house.city_name = localityComponent.long_name
    this.house.address = details.formatted_address
    this.house.latitude = details.latitude
    this.house.longitude = details.longitude
    let data = {
      address: this.house.address,
      latitude: this.house.latitude,
      longitude: this.house.longitude,
    }
    if (this.house.id)
      await this.house.save({
        method: "PUT",
        url: `houses/${this.house.id}`,
        data: { house: data },
      })
  }
  /**
   * If a value is given, update the house object with the value.
   * If a value and rules are given, check the rules are valid before calling the API.
   */
  async updateHouse(
    field: string | [string, number],
    value?: any,
    rules?: Array<(value: any) => boolean | string>
  ) {
    let index: null | number = null
    if (Array.isArray(field)) {
      index = field[1]
      field = field[0]
    }
    const house = this.house as Record<string, any>
    if (value === undefined) {
      value = house[field] === undefined ? null : house[field]
      house[field] = value === undefined ? null : value
    } else {
      if (rules && rules.some((rule) => rule(value) != true)) return
      if (index == null) house[field] = value
      else {
        if (house[field] == null) house[field] = []
        house[field][index] = value
        value = house[field] // the value to send to the API
      }
    }
    if (this.house.id) {
      await this.house.save({
        method: "PUT",
        url: `houses/${this.house.id}`,
        data: {
          house: { [field]: value },
        },
      })
      if (
        (field === "price" || field === "living_area") &&
        this.house &&
        this.house.price &&
        this.house.living_area
      ) {
        this.house.pricem2 = Math.round(
          this.house.price / this.house.living_area
        )
      }
    }
  }
  isChecked(field: string, value?: any) {
    const house = this.house as Record<string, any>
    return house[field] && house[field].includes(value)
  }
  updateHouseCheckbox(field: string, value?: any) {
    const house = this.house as Record<string, any>
    if (house[field].includes(value)) {
      house[field].splice(house[field].indexOf(value), 1)
    } else {
      house[field].push(value)
    }
    this.updateHouse(field, house[field])
  }
  updateHouseRadio(field: HouseFormDataField, value: any) {
    const house = this.house as Record<string, any>
    if (field.name === undefined) return
    if (house[field.name] === value && !field.required) house[field.name] = null
    else house[field.name] = value
    this.updateHouse(field.name)
  }

  onIntegerKeydown(event: KeyboardEvent) {
    preventNonNumericKeys(event)
  }
  items(key: string) {
    let results
    if (
      key == "property_type" ||
      key == "exposition" ||
      key == "garden_exposition"
    )
      results = Object.entries(getEnumValues(key)).map((entry) => ({
        value: parseInt(entry[0]),
        text: entry[1],
      }))
    else
      results = Object.entries(getEnumValues(key)).map(([value, text]) => ({
        value,
        text,
      }))
    return results
  }
  addField(fieldName: keyof House) {
    if ((this.house[fieldName] as string[]).length == 0) {
      let data: Record<string, string[]> = {}
      data[fieldName] = []
      this.house.assign(data)
    }
    ;(this.house[fieldName] as string[]).push("")
  }
  removeField(fieldName: keyof House, index: number) {
    ;(this.house[fieldName] as string[]).splice(index - 1, 1)
    this.updateHouse(fieldName)
  }
  validateForm() {
    const form = this.$refs.form as HTMLFormElement
    return form.validate()
  }
  customFilter(item: any) {
    return item
  }
  notEmpty = (value: string | Array<any> | null) => {
    return !!(value && value.length) || "Champ requis"
  }
  phoneNumber = (value: string) =>
    !value || isValidPhoneNumber(value) || "Numéro incorrect"
}
