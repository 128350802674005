import House from "~/models/House"

export type HouseFormDataField = {
  title?: string
  subtitle?: string
  type: string
  width?: string
  inline?: string
  name?: keyof House
  keyValue?: string
  unit?: string
  multiple?: boolean
  display?: string
  grid?: string
  boolean?: boolean
  required?: boolean
  markAsRequired?: boolean
  tip?: string
}

export type HouseFormDataColumn = {
  fields: HouseFormDataField[]
}

export type HouseFormDataTab = {
  name: string
  columns: HouseFormDataColumn[]
  type: string
  field?: string
}

export const houseFormData: HouseFormDataTab[] = [
  {
    name: "Général",
    type: "new",
    columns: [
      {
        fields: [
          {
            title: "Informations générales",
            type: "group",
          },
          {
            subtitle: "Titre",
            type: "text",
            name: "title",
            markAsRequired: true,
            required: true,
          },
          {
            subtitle: "Prix",
            type: "integer",
            width: "small",
            name: "price",
            unit: "€",
            inline: "inline",
            markAsRequired: true,
            required: true,
          },
          {
            subtitle: "Type de bien",
            type: "radioButton",
            width: "medium",
            name: "property_type",
            keyValue: "property_type",
            required: true,
          },
          {
            subtitle: "Description",
            type: "textarea",
            name: "description",
            markAsRequired: true,
            required: true,
          },
          {
            subtitle: "Adresse",
            type: "google",
            name: "address",
            required: true,
            markAsRequired: true,
          },
        ],
      },
      {
        fields: [
          {
            title: "Informations agent",
            type: "group",
          },
          {
            subtitle: "Off market",
            type: "radioButton",
            name: "off_market",
            keyValue: "off_market",
            boolean: true,
          },
          {
            subtitle: "Nom de l'agence",
            type: "text",
            name: "agency_name",
          },
          {
            subtitle: "Numéro de téléphone de l'agence",
            type: "phone",
            width: "small",
            name: "agency_phone",
          },
          {
            type: "separator",
          },
          {
            subtitle: "Nom de l'agent",
            type: "text",
            name: "agent_name",
          },
          {
            subtitle: "Numéro de téléphone de l'agent",
            type: "phone",
            width: "small",
            name: "agent_phone",
          },
        ],
      },
    ],
  },
  {
    name: "Informations de base",
    type: "house",
    field: "additional_note",
    columns: [
      {
        fields: [
          {
            subtitle: "Adresse",
            markAsRequired: true,
            type: "google",
            width: "large",
            name: "address",
            inline: "inline",
          },
          {
            subtitle: "Type de bien",
            type: "radioButton",
            width: "medium",
            name: "property_type",
            keyValue: "property_type",
            required: true,
            inline: "inline",
          },
          { type: "clear-inline" },
          {
            subtitle: "Prix",
            type: "integer",
            width: "small",
            name: "price",
            unit: "€",
            inline: "inline",
          },
          {
            subtitle: "Surface totale",
            tip: "Surface servant à calculer le prix au m²",
            markAsRequired: true,
            type: "number",
            width: "small",
            name: "living_area",
            unit: "m²",
            inline: "inline",
          },
          {
            subtitle: "Nb. de pièces",
            markAsRequired: true,
            type: "integer",
            width: "xsmall",
            name: "room_count",
            inline: "inline",
          },
          {
            subtitle: "Nb. de chambres",
            markAsRequired: true,
            type: "integer",
            width: "xsmall",
            name: "bedroom_count",
            inline: "inline",
          },
          { type: "clear-inline" },
          {
            subtitle: "Surface terrain",
            type: "number",
            width: "small",
            name: "estate_area",
            unit: "m²",
            inline: "inline",
          },
          {
            subtitle: "Mitoyen",
            type: "radioButton",
            name: "adjoining",
            keyValue: "adjoining",
            boolean: true,
            inline: "inline",
          },
          {
            subtitle: "En copropriété",
            type: "radioButton",
            name: "house_in_copro",
            keyValue: "house_in_copro",
            boolean: true,
            inline: "inline",
          },
          { type: "clear-inline" },
          { type: "separator" },
          {
            subtitle: "Statut",
            type: "radioButton",
            width: "large",
            name: "sell_status",
            keyValue: "sell_status",
            inline: "inline",
          },
          {
            subtitle: "Pépite",
            type: "radioButton",
            name: "nugget",
            keyValue: "nugget",
            inline: "inline",
            boolean: true,
          },
        ],
      },
    ],
  },
  {
    name: "Informations de base",
    type: "flat",
    field: "additional_note",
    columns: [
      {
        fields: [
          {
            subtitle: "Adresse",
            markAsRequired: true,
            type: "google",
            width: "large",
            name: "address",
            inline: "inline",
          },
          {
            subtitle: "Type de bien",
            type: "radioButton",
            width: "medium",
            name: "property_type",
            keyValue: "property_type",
            required: true,
            inline: "inline",
          },
          { type: "clear-inline" },
          {
            subtitle: "Prix",
            type: "integer",
            width: "small",
            name: "price",
            unit: "€",
            inline: "inline",
          },
          {
            subtitle: "Surface totale",
            tip: "Surface servant à calculer le prix au m²",
            markAsRequired: true,
            type: "number",
            width: "small",
            name: "living_area",
            unit: "m²",
            inline: "inline",
          },
          {
            subtitle: "Nb. de pièces",
            markAsRequired: true,
            type: "integer",
            width: "xsmall",
            name: "room_count",
            inline: "inline",
          },
          {
            subtitle: "Nb. de chambres",
            markAsRequired: true,
            type: "integer",
            width: "xsmall",
            name: "bedroom_count",
            inline: "inline top",
          },
          { type: "clear-inline" },
          {
            subtitle: "Étage",
            markAsRequired: true,
            type: "integer",
            width: "xsmall",
            name: "floor",
            inline: "inline",
            display: "flat",
          },
          {
            type: "textOnly",
            title: "/",
            inline: "textOnly",
            display: "flat",
          },
          {
            subtitle: " ",
            type: "integer",
            width: "xsmall",
            name: "floor_count",
            inline: "inline",
            display: "flat",
          },
          {
            subtitle: "RDC",
            type: "radioButton",
            name: "ground_floor",
            keyValue: "ground_floor",
            inline: "inline",
            boolean: true,
          },
          {
            subtitle: "Ascenseur",
            type: "radioButton",
            name: "elevator",
            keyValue: "elevator",
            inline: "inline",
            boolean: true,
          },
          { type: "clear-inline" },
          { type: "separator" },
          {
            subtitle: "Statut",
            type: "radioButton",
            width: "large",
            name: "sell_status",
            keyValue: "sell_status",
            inline: "inline",
          },
          {
            subtitle: "Pépite",
            type: "radioButton",
            name: "nugget",
            keyValue: "nugget",
            inline: "inline",
            boolean: true,
          },
        ],
      },
    ],
  },
  {
    name: "Informations complémentaires",
    type: "flat",
    field: "additional_note",
    columns: [
      {
        fields: [
          {
            subtitle: "OFF",
            type: "radioButton",
            name: "off_market",
            keyValue: "off_market",
            inline: "inline",
            boolean: true,
          },
          {
            subtitle: "Exclusivité",
            type: "radioButton",
            name: "exclusivity",
            keyValue: "exclusivity",
            inline: "inline",
            boolean: true,
          },
          {
            type: "separator",
          },
          {
            title: "Général",
            type: "group",
          },
          {
            subtitle: "DPE",
            type: "radioButton",
            name: "dpe_class",
            keyValue: "dpe_class",
            inline: "inline",
          },
          {
            subtitle: "Année de construction",
            type: "number",
            width: "small",
            name: "construction_year",
            inline: "inline",
          },
          { type: "clear-inline" },
          {
            subtitle: "Exposition du séjour",
            type: "checkbox",
            width: "medium",
            name: "exposition",
            keyValue: "exposition",
            grid: "columns-4",
            inline: "inline",
          },
          { type: "clear-inline" },
          {
            subtitle: "Dernier étage",
            type: "radioButton",
            name: "last_floor",
            keyValue: "last_floor",
            inline: "inline",
            boolean: true,
          },
          {
            subtitle: "Parking",
            type: "radioButton",
            name: "parking_type",
            keyValue: "parking_type",
            inline: "inline",
          },
          {
            subtitle: "Cave",
            type: "radioButton",
            name: "cellar",
            keyValue: "cellar",
            inline: "inline",
            boolean: true,
          },
          { type: "clear-inline" },
          {
            subtitle: "Chauffage",
            type: "radioButton",
            width: "medium",
            name: "heating_sharing",
            keyValue: "heating_sharing",
            inline: "inline",
          },
          {
            subtitle: "Balcon",
            type: "radioButton",
            name: "balcony",
            keyValue: "balcony",
            inline: "inline",
            boolean: true,
          },
          {
            subtitle: "Superficie",
            type: "number",
            width: "small",
            name: "balcony_area",
            unit: "m²",
            inline: "inline",
          },
          { type: "clear-inline" },
          {
            subtitle: "Type de chauffage",
            type: "radioButton",
            width: "medium",
            name: "heating_type",
            keyValue: "heating_type",
            inline: "inline",
          },
          {
            subtitle: "Terrasse",
            type: "radioButton",
            name: "terrace",
            keyValue: "terrace",
            inline: "inline",
            boolean: true,
          },
          {
            subtitle: "Superficie",
            type: "number",
            width: "small",
            name: "terrace_area",
            unit: "m²",
            inline: "inline",
          },
          { type: "clear-inline" },
          {
            subtitle: "Charges mensuelles",
            type: "number",
            width: "small",
            name: "monthly_charges",
            unit: "€",
            inline: "inline",
          },
          {
            subtitle: "Taxe foncière",
            type: "integer",
            width: "small",
            name: "property_tax",
            unit: "€",
            inline: "inline",
          },
          {
            subtitle: "Jardin",
            type: "radioButton",
            name: "garden",
            keyValue: "garden",
            inline: "inline",
            boolean: true,
          },
          {
            subtitle: "Superficie",
            type: "number",
            width: "small",
            name: "garden_area",
            unit: "m²",
            inline: "inline",
          },
          { type: "separator" },
          {
            title: "Pièces",
            type: "group",
          },
          {
            subtitle: "Nombre et surfaces des chambres",
            type: "title",
            markAsRequired: true,
          },
          {
            type: "integer",
            width: "xsmall",
            name: "bedroom_count",
            inline: "inline top",
          },
          {
            type: "number",
            width: "small",
            name: "bedroom_areas",
            multiple: true,
            unit: "m²",
            inline: "inline top",
          },
          { type: "clear-inline" },
          {
            subtitle: "Cuisine",
            type: "number",
            width: "small",
            name: "kitchen_area",
            unit: "m²",
            inline: "inline",
          },
          {
            subtitle: "Type de cuisine",
            type: "radioButton",
            width: "medium",
            name: "kitchen_type",
            keyValue: "kitchen_type",
            inline: "inline",
          },
          { type: "clear-inline" },
          {
            subtitle: "Salon",
            type: "number",
            width: "small",
            name: "living_room_area",
            unit: "m²",
            inline: "inline",
          },
          {
            subtitle: "Hauteur sous plafond",
            type: "number",
            width: "small",
            name: "ceiling_height",
            unit: "m",
            inline: "inline",
          },
          { type: "clear-inline" },
          {
            subtitle: "Sd. bain",
            type: "integer",
            width: "xsmall",
            name: "bathroom_count",
            inline: "inline",
          },
          {
            subtitle: "Sd. eau",
            type: "integer",
            width: "xsmall",
            name: "shower_room_count",
            inline: "inline",
          },
          {
            subtitle: "WC",
            type: "integer",
            width: "xsmall",
            name: "wc_count",
            inline: "inline",
          },
          {
            subtitle: "Séparés",
            type: "radioButton",
            name: "separate_wc",
            keyValue: "separate_wc",
            inline: "inline",
            boolean: true,
          },
          {
            subtitle: "Sanibroyeur",
            type: "radioButton",
            name: "sanibrinder",
            keyValue: "sanibrinder",
            inline: "inline",
            boolean: true,
          },
          { type: "separator" },
          {
            title: "Prestations",
            type: "group",
          },
          {
            subtitle: "État du bien",
            type: "radioButton",
            name: "condition",
            keyValue: "condition",
          },
          {
            subtitle: "Orientation",
            type: "radioButton",
            name: "orientation",
            keyValue: "orientation",
          },
          { type: "separator" },
          {
            title: "Copropriété",
            type: "group",
          },
          {
            subtitle: "État de la copropriété",
            type: "radioButton",
            width: "medium",
            name: "copro_condition",
            keyValue: "copro_condition",
            inline: "inline",
          },
          {
            subtitle: "Quote part travaux estimée",
            type: "integer",
            width: "small",
            name: "copro_work_price",
            unit: "€",
            inline: "inline",
          },
          { type: "clear-inline" },
          {
            subtitle: "Gardien",
            type: "radioButton",
            name: "guard",
            keyValue: "guard",
            boolean: true,
            inline: "inline",
          },
          {
            subtitle: "Local vélo",
            type: "radioButton",
            name: "bike_room",
            keyValue: "bike_room",
            boolean: true,
            inline: "inline",
          },
        ],
      },
    ],
  },

  {
    name: "Informations complémentaires",
    type: "house",
    field: "additional_note",
    columns: [
      {
        fields: [
          {
            subtitle: "OFF",
            type: "radioButton",
            name: "off_market",
            keyValue: "off_market",
            inline: "inline",
            boolean: true,
          },
          {
            subtitle: "Exclusivité",
            type: "radioButton",
            name: "exclusivity",
            keyValue: "exclusivity",
            inline: "inline",
            boolean: true,
          },
          {
            type: "separator",
          },
          {
            title: "Général",
            type: "group",
          },
          {
            subtitle: "DPE",
            type: "radioButton",
            name: "dpe_class",
            keyValue: "dpe_class",
            inline: "inline",
          },
          {
            subtitle: "Année de construction",
            type: "number",
            width: "small",
            name: "construction_year",
            inline: "inline",
          },
          { type: "clear-inline" },
          {
            subtitle: "Exposition du séjour",
            type: "checkbox",
            width: "medium",
            name: "exposition",
            keyValue: "exposition",
            grid: "columns-4",
            inline: "inline",
          },
          { type: "clear-inline" },
          {
            subtitle: "Jardin",
            type: "radioButton",
            name: "garden",
            keyValue: "garden",
            inline: "inline",
            boolean: true,
          },
          {
            subtitle: "Superficie",
            type: "number",
            width: "small",
            name: "garden_area",
            unit: "m²",
            inline: "inline",
          },
          {
            subtitle: "Exposition Jardin",
            type: "checkbox",
            width: "medium",
            name: "garden_exposition",
            keyValue: "garden_exposition",
            grid: "columns-4",
            inline: "inline",
          },
          { type: "clear-inline" },
          {
            subtitle: "Garage",
            type: "radioButton",
            name: "parking",
            keyValue: "parking",
            inline: "inline",
            boolean: true,
          },
          {
            subtitle: "Sous-sol",
            type: "radioButton",
            name: "underground_parking",
            keyValue: "underground_parking",
            inline: "inline",
            boolean: true,
          },
          {
            subtitle: "Taxe foncière",
            type: "integer",
            width: "small",
            name: "property_tax",
            unit: "€",
            inline: "inline",
          },
          {
            subtitle: "État du bien",
            type: "radioButton",
            name: "condition",
            keyValue: "condition",
          },
          { type: "separator" },
          {
            title: "Pièces",
            type: "group",
          },
          {
            subtitle: "Nombre et surfaces des chambres",
            type: "title",
            markAsRequired: true,
          },
          {
            type: "integer",
            width: "xsmall",
            name: "bedroom_count",
            inline: "inline top",
          },
          {
            type: "number",
            width: "small",
            name: "bedroom_areas",
            multiple: true,
            unit: "m²",
            inline: "inline top",
          },
          { type: "clear-inline" },
          {
            subtitle: "Cuisine",
            type: "number",
            width: "small",
            name: "kitchen_area",
            unit: "m²",
            inline: "inline",
          },
          {
            subtitle: "Type de cuisine",
            type: "radioButton",
            width: "medium",
            name: "kitchen_type",
            keyValue: "kitchen_type",
            inline: "inline",
          },
          { type: "clear-inline" },
          {
            subtitle: "Salon",
            type: "number",
            width: "small",
            name: "living_room_area",
            unit: "m²",
            inline: "inline",
          },
          {
            subtitle: "Sd. bain",
            type: "integer",
            width: "xsmall",
            name: "bathroom_count",
            inline: "inline",
          },
          {
            subtitle: "Sd. eau",
            type: "integer",
            width: "xsmall",
            name: "shower_room_count",
            inline: "inline",
          },
          {
            subtitle: "WC",
            type: "integer",
            width: "xsmall",
            name: "wc_count",
            inline: "inline",
          },
          { type: "separator" },
          {
            title: "Qualité de l'environnement",
            type: "group",
          },
          {
            subtitle: "Temps vers transports en commun",
            type: "integer",
            width: "small",
            name: "time_to_public_transport",
            unit: "mins",
            inline: "inline",
            tip: "Temps vers le train / RER le plus proche",
          },
        ],
      },
    ],
  },
]
