const paris = {
  lat: 48.8534,
  lng: 2.3488,
}

/**
 * Wrapper around a google maps geocoder result with utility functions
 */
class GeocoderResult {
  constructor(public result: google.maps.GeocoderResult) {}

  get formatted_address() {
    return this.result.formatted_address
  }
  get place_id() {
    return this.result.place_id
  }
  get address_components() {
    return this.result.address_components
  }
  get latitude() {
    return this.result.geometry.location.lat()
  }
  get longitude() {
    return this.result.geometry.location.lng()
  }

  find(adressComponent: string, format: "short" | "long" = "long") {
    const found = this.address_components.find(({ types }) =>
      types.includes(adressComponent)
    )
    return found ? found[format == "long" ? "long_name" : "short_name"] : null
  }

  join(
    addressComponents: string[],
    joint = " ",
    format: "short" | "long" = "long"
  ) {
    return addressComponents
      .map((component) => this.find(component, format))
      .filter((i) => i)
      .join(joint)
  }
}

/**
 * Return an address from a google's placeId
 */
function geocodePlaceId(placeId: string): Promise<GeocoderResult> {
  return new Promise((resolve, reject) => {
    new google.maps.Geocoder().geocode({ placeId }, (results, status) => {
      if (status == "OK") resolve(new GeocoderResult(results[0]))
      else reject(status)
    })
  })
}

/**
 * Search for a location nearby Paris
 * @param types the type of content to be retrieved. Ex: 'address' when a specific street address is needed
 * Available types: https://developers.google.com/maps/documentation/places/web-service/supported_types#table3
 */
function autocompletePlace(
  input = "",
  types = ["address"]
): Promise<google.maps.places.AutocompletePrediction[]> {
  const options = {
    input,
    location: new google.maps.LatLng(paris),
    radius: 40_000,
    componentRestrictions: { country: "fr" },
    types,
  }
  return new Promise((resolve, reject) => {
    new google.maps.places.AutocompleteService().getPlacePredictions(
      options,
      (results, status) => {
        if (status == "OK") resolve(results)
        else if (status == "ZERO_RESULTS") resolve([])
        else reject(status)
      }
    )
  })
}

export { autocompletePlace, geocodePlaceId }
